<template>
  <div class="chat-page">
    <div class="max-width">
      <v-row>
        <!-- users -->
        <v-col cols="12" md="4" class="px-2 pb-0">
          <div class="chat-content">
            <div
              class="head-section d-flex align-center justify-space-between py-4 px-8"
            >
              <span class="black--text font-18 font-500"
                >All Conversations</span
              >
            </div>
            <div v-if="chats.length > 0" class="px-3">
              <div
                class="mt-3 dark-shadow px-2 py-2 radius-15 white cursor-pointer chat-style"
                @click="getMessages(chat, true)"
                :class="chat._id == paramID ? 'selected-chat' : ''"
                v-for="(chat, i) in chats"
                :key="i"
              >
                <v-row align="end">
                  <v-col cols="2">
                    <div class="user-avatar-mini mx-auto">
                      <img
                        width="100%"
                        height="100%"
                        class="object-contain"
                        :src="chat.image ? chat.image.url : userAvatar"
                        alt=""
                      />
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <div class="d-flex align-start justify-space-between">
                      <span class="font-16 black--text d-block">{{
                        chat.name
                      }}</span>
                      <span class="grey--text font-15 font-300">
                        {{ $moment(chat.date).format("LT") }}
                      </span>
                    </div>
                    <span class="font-14 black--text d-block">{{
                      chat.latsMessage
                    }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="text-center mt-4" v-else>
              <span class="primary--text font-22 font-500">No Chats Found</span>
            </div>
          </div>
        </v-col>
        <!-- content -->
        <v-col cols="12" md="8" class="px-2 pb-0">
          <div class="d-flex align-center py-4 px-3">
            <div class="user-avatar-large mr-3">
              <img
                width="100%"
                height="100%"
                class="object-contain"
                :src="selectedUser.image ? selectedUser.image.url : userAvatar"
                alt=""
              />
            </div>
            <span class="black--text font-18 font-500 d-block">{{
              selectedUser.name
            }}</span>
          </div>
          <div class="radius-15 dark-shadow relative">
            <!-- messages -->
            <div
              v-if="currentPage < totalPages"
              class="text-center cursor-pointer loading-messages"
              @click="loadMore"
            >
              <span class="font-12">Load More</span>
            </div>
            <div
              class="message-content pr-4 mr-4 pt-5"
              id="messages"
              v-if="messages.length > 0"
            >
              <div class="mb-6 px-3" v-for="(message, i) in messages" :key="i">
                <div
                  class="px-4 py-3 radius-15"
                  :class="
                    message.sender == userID
                      ? 'user-from ml-auto white'
                      : 'user-to blue'
                  "
                >
                  <span
                    class="font-14"
                    :class="
                      message.sender == userID ? 'black--text ' : 'white--text '
                    "
                    >{{ message.message }}</span
                  >
                </div>
                <div
                  class="mb-2 d-flex align-center"
                  :class="message.sender == userID ? 'justify-end' : ''"
                >
                  <span class="gray--text font-14 d-block">{{
                    $moment(message.updatedAt).calendar()
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="text-center d-flex align-center justify-center h-100 w-100"
              v-else
            >
              <span>Select Chat To Start Conversations</span>
            </div>

            <!-- input -->
            <div class="base-input mb-0 mt-2" v-if="selectedUser._id">
              <v-textarea
                class="radius-15"
                :hide-details="true"
                v-model="message"
                append-icon="mdi-send"
                filled
                clear-icon="mdi-close-circle"
                clearable
                color="purple"
                placeholder="Message"
                type="text"
                outlined
                @click:append="sendMessage"
                @click:clear="clearMessage"
                rows="3"
                no-resize
              ></v-textarea>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    userAvatar: require("@/assets/images/global/avatar.png"),
    show: false,
    message: "",
    messages: [],
    chats: [],
    selectedChat: {},
    selectedUser: {},
    routeItem: {},
    paramID: "",
    currentPage: 1,
    totalPages: 0,
    busy: false,
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  watch: {
    $route: {
      async handler() {
        await this.checkRoute();
      },
    },
  },
  methods: {
    clearMessage() {
      this.message = "";
    },
    async sendMessage() {
      if (!this.message) return;
      const { data } = await this.$axios.post("/chat/message", {
        message: this.message,
        reciver: this.paramID,
      });
      if (data.success) {
        this.messages.unshift({
          message: this.message,
          sender: this.userID,
          updatedAt: this.$moment(),
        });
        await this.getAllChats();
        this.clearMessage();
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async getMessages(item, onInitLoad = false, onScrollLoad = false) {
      if (item) {
        this.selectedChat = item;
        this.selectedUser = item;
        this.$router.replace("/chat/" + item.userId);
      }
      if (onInitLoad) {
        this.messages = [];
        this.totalPages = Math.ceil(+this.selectedChat.count / 8);
      }
      if (onScrollLoad) {
        this.currentPage = this.currentPage + 1;
      }
      if (this.messageCountNumber >= this.totalPages) return;
      let { data } = await this.$axios.get(
        "/chat/" +
          this.selectedChat._id +
          `?pageNumber=${this.currentPage}&pageSize=8`
      );
      let arrMessages = data.messages;
      if (onInitLoad) {
        arrMessages = data.messages.reverse();
      }
      if (data.success && onInitLoad) {
        for (let i = 0; i < arrMessages.length; i++) {
          this.messages.unshift(arrMessages[i]);
        }
      } else if (data.success && !onInitLoad) {
        for (let i = 0; i < arrMessages.length; i++) {
          this.messages.push(arrMessages[i]);
        }
      }
    },
    async getAllChats() {
      let { data } = await this.$axios.get("/chat");
      if (data.success) {
        this.chats = data.chats.map((chat) => {
          return {
            selected: false,
            ...chat,
          };
        });
      }
    },
    async checkRoute() {
      this.paramID = this.$route.params.id;
      if (this.paramID) {
        this.selectedChat = this.chats.filter(
          (chat) => chat.userId == this.paramID
        )[0];
        if (this.selectedChat) {
          await this.getMessages(this.selectedChat, true);
        } else {
          let { data } = await this.$axios.get(`/users/${this.paramID}`);
          if (data) {
            this.selectedUser = data.data;
          }
        }
      }
    },
    loadMore() {
      // let messageDiv = document.getElementById("messages");

      // messageDiv.scrollTop = messageDiv.scrollHeight;
      if (this.selectedChat._id) {
        this.busy = true;
        setTimeout(async () => {
          await this.getMessages(null, null, true);
          this.busy = false;
        }, 1000);
      }
    },
  },
  async created() {
    await this.getAllChats();
    await this.checkRoute();
  },
};
</script>

<style lang="scss">
.chat-page {
  .head-section {
    border-bottom: 1px solid $grayicon;
  }

  .message-content {
    height: 600px;
    max-height: 500px;
    background: #f1f1f1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .loading-messages {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    background: #74748b;
    padding: 3px 50px;
    border-radius: 5px;
    color: white;
  }
  .user-avatar-mini {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;
  }
  .user-avatar-large {
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
  }
  .user-to,
  .user-from {
    border: 1px solid #eaedf3 !important;
    max-width: 500px;
    position: relative;
    border-radius: 10px;
    border-top-left-radius: 0px;
  }
  .user-from {
    border-radius: 10px;
    border-top-right-radius: 0px;
  }
  .chat-style {
    position: relative;
    border-left: 2px solid transparent;
    transition: 0.3s;
  }
  .selected-chat {
    border-left: 4px solid $blue !important;
  }
}
@keyframes opacityLoading {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
